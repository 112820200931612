import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { EntityLink, Icon, useEntity, useLang, useModal } from '@shapeable/ui';
import { EntityMainTitleHeader } from './entity-main-title-header';
import { PAGE_GET_INVOLVED } from '../../data';
import { FahWidgetModal } from '../elements/fah-widget-modal';

import { DottedChevronRightHeavyIconGlyph } from '@shapeable/icons';
import { BackButton } from '../elements/back-button';
import { classNames } from '@shapeable/utils';
const cls = classNames('main-header-layout');

// -------- Types -------->

export type MainHeaderLayoutProps = Classable & HasChildren & { 
  entity?: Entity;
};

export const MainHeaderLayoutDefaultProps: Omit<MainHeaderLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(3)} 0;
  `,
});

const BodyStyles = breakpoints({
  base: css`
    display: flex;
    gap: ${theme.UNIT(4)};
    flex-direction: column;
    justify-content: space-between;
  `,
  tablet: css`
    flex-direction: row;
    
  `
});

const HeaderStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(2)};
  `,
});



const TitleAndImageStyles = breakpoints({
  base: css`
   
  `,
});

const ButtonContainerStyles = breakpoints({
  base: css`
    display: flex;
    gap: ${theme.UNIT(3)};
    flex-direction: column;
    justify-content: center;
    font-weight: 500;

  `,
});

const ButtonStyles = breakpoints({
  base: css`
    text-transform: uppercase;
    line-height: 1.1em;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    text-decoration: none;
    align-items: center;
    margin-right: ${theme.UNIT(2)};
    border: 1.5px dotted ${theme.COLOR(cls.color(), 'sub')};
    border-radius: 20px;
    padding: ${theme.UNIT(0)} ${theme.UNIT(4)};
    font-size: ${theme.FONT_SIZE(13)};
    height: 38px;
    ${theme.FILL(cls.color(), 'sub')};
    color: ${theme.COLOR(cls.color(), 'sub')};

    &:hover {
      color: ${theme.COLOR('link-hover')};
      ${theme.FILL('link-hover')};
      border-color: ${theme.COLOR('link-hover')};
    }
    

  `,
});

const ButtonIconStyles = breakpoints({
  base: css`
    height: 20px;
    margin-left: ${theme.UNIT(2)};
    color: ${theme.COLOR('dark')};
  `,
});

const IconStyles = breakpoints({
  base: css`
    
  `,
});

const BackButtonStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(2)};
  `,
});

const FahWidgetStyles = breakpoints({
  base: css`

  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Body: styled.div`${BodyStyles}`,
    Header: styled.div`${HeaderStyles}`,
    BackButton: styled(BackButton)`${BackButtonStyles}`,
    TitleAndImage: styled(EntityMainTitleHeader)`${TitleAndImageStyles}`,

    ButtonContainer: styled.div`${ButtonContainerStyles}`,
      Button: styled.div`${ButtonStyles}`,
      ButtonIcon: styled(DottedChevronRightHeavyIconGlyph)`${ButtonIconStyles}`,
      Link: styled(EntityLink)``,

    Icon: styled(Icon)`${IconStyles}`,
      
      FahWidget: styled(FahWidgetModal)`${FahWidgetStyles}`,
};

export const MainHeaderLayout: Shapeable.FC<MainHeaderLayoutProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);

  const t = useLang();

  const modal = useModal();
  const inCrisisOnClick = () => {
    modal.show(<FahWidgetModal />, { color: '#000000', title: 'Find a Helpline', opacity: 0.8 } );
  };
  
  return (
    
   <My.Container className={cls.name(className)}>
      {/* {
        widgetIsVisible && 
        <My.FahWidget onOverlayClick={() => { setWidgetIsVisible(false) }} />
      } */}
      <My.Body>
        <My.Header>
          <My.BackButton href='/countries'/>
          <My.TitleAndImage entity={entity} label='Country Profile' imageSize={130} />
        </My.Header>


        <My.ButtonContainer>
          <My.Link entity={PAGE_GET_INVOLVED}><My.Button >{t('Become Involved')}<My.ButtonIcon /></My.Button></My.Link>
          <My.Button role="button" onClick={inCrisisOnClick}>{t('Are You In A Crisis Right Now?')}<My.ButtonIcon /></My.Button>
        </My.ButtonContainer>

      </My.Body>
   {children}
   </My.Container>
  )
};

MainHeaderLayout.defaultProps = MainHeaderLayoutDefaultProps;
MainHeaderLayout.cls = cls;