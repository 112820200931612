import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity, ContentNode as TContentNode, ContentNode as TContetNode } from '@shapeable/types';
import AnimateHeight from 'react-animate-height';
import { breakpoints, theme } from '@shapeable/theme';
import { ContentNode, ContentTitle, useLang, DottedLine, Icon } from '@shapeable/ui';
import { ChevronDownIconGlyph } from '@shapeable/icons';
import { classNames } from '@shapeable/utils';
const cls = classNames('entity-embed-reveal');

// -------- Types -------->

export type EntityContentRevealProps = Classable & HasChildren & { 
  entity?: TContentNode;
  title?: string;
  onClick?: (event: any) => void;
};

export const EntityContentRevealDefaultProps: Omit<EntityContentRevealProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {
  isExpanded?: boolean;
}

type SelectIndicatorProps = {
  isExpanded?: boolean;
  isCurrent?: boolean;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    .shp--entity-value__name {
      &:hover {
        color: ${theme.COLOR('link-hover')};
      }
    }
  `,
});

const BodyStyles = breakpoints({
  base: css`
    
  `,
});

const TitleContainerStyles = breakpoints({
  base: css`
    display: flex;
    cursor: pointer;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    
  `,
});

const ContentTitleStyles = breakpoints({
  base: css`
    line-height: 1.5em;
  `,
  tablet: css`
    line-height: 2em;
  `,
});

const ContentStyles = breakpoints({
  base: css`
    font-weight: 400;
    color: ${theme.COLOR('text')};
    margin-top: ${theme.UNIT(2)};
    margin-bottom: ${theme.UNIT(12)};
  `,
});

const DottedLineStyles = breakpoints({
  base: css`
  `,
});

const SelectIndicatorStyles = breakpoints({
  base: css`
    display: inline-flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    transition: transform 0.3s;
    ${theme.FILL('text-sidebar')};

    ${({ isExpanded }: SelectIndicatorProps) => isExpanded && css`
    transform: rotate(-180deg);
  `}
    ${({ isCurrent }: SelectIndicatorProps) => isCurrent && css`
      ${theme.FILL('strong')};
  `}
  `,
});

const IconStyles = breakpoints({
  base: css`
  
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Body: styled.div`${BodyStyles}`,
      TitleContainer: styled.div`${TitleContainerStyles}`,
      SelectIndicator: styled.div<SelectIndicatorProps>`${SelectIndicatorStyles}`,
        Icon: styled(Icon).attrs(cls.attr('icon'))`${IconStyles}`,
      ContentTitle: styled(ContentTitle)`${ContentTitleStyles}`,
      Content: styled(ContentNode)`${ContentStyles}`,
      
      DottedLine: styled(DottedLine)`${DottedLineStyles}`,
};

export const EntityContentReveal: Shapeable.FC<EntityContentRevealProps> = (props) => {
  const { className, children, title, entity, onClick } = props;

  const t = useLang();
  const [ isExpanded, setIsExpanded ] = useState<boolean>(false);
  
  return (
   <My.Container className={cls.name(className)}>
    <My.DottedLine height={2} gap={5} />
    <My.TitleContainer onClick={(event: any) => { event.stopPropagation(); setIsExpanded((isExpanded) => !isExpanded)}}>
      <My.ContentTitle>{t(title)}</My.ContentTitle>
      <My.SelectIndicator isCurrent isExpanded={isExpanded} ><My.Icon size={16} ><ChevronDownIconGlyph /></My.Icon></My.SelectIndicator>
    </My.TitleContainer>
      <AnimateHeight duration={300} height={isExpanded ? "auto" : 0}>
        <My.DottedLine height={2} gap={5} />
        <My.Content entity={entity as TContentNode} /> 
      </AnimateHeight>
      {children}
    </My.Container>
  )
};

EntityContentReveal.defaultProps = EntityContentRevealDefaultProps;
EntityContentReveal.cls = cls;