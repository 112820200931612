import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { Economy } from '@shapeable/lli-types';
import { breakpoints, theme } from '@shapeable/theme';
import { ComponentPropsProvider, SiteHeaderLayout, SiteHeaderProvider, SliceLayoutBoundary, useAuth, useEntity, useView } from '@shapeable/ui';
import { TabSpec, Tabs } from '../elements/tabs';
import { CountryWorkstreamLayout } from './country-workstream-layout';
import { CountryCommunityLayout } from './country-community-layout';
import { CountryOverviewLayout } from './country-overview-layout';
import { CountryResourcesLayout } from './country-resources-layout';
import { MainHeaderLayout } from './main-header-layout';
import AnimateHeight from 'react-animate-height';
import { useActivePerson } from '../../hooks/use-active-person';
import { compact } from 'lodash';

import { classNames } from '@shapeable/utils';
import { CountryPublicationsLayout } from './country-publications-layout';
const cls = classNames('countries-layout');


const COLOR_MAP = [
  
]
// -------- Types -------->

export type CountryProfileView =  'overview' | 'activity' | 'community' | 'assets' | 'publications';

export type CountryLayoutProps = Classable & HasChildren & { 
  entity: Economy;
};

export const CountryLayoutDefaultProps: Omit<CountryLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    .shp--site-header-layout__navigation-and-controls {
      padding-top: 10px;
    }

    font-family: "IBM Plex Sans", sans-serif;
  `,
});

const HeaderStyles = breakpoints({
  base: css`
    
  `,
});

const LayoutStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(8)};
    margin-top: ${theme.UNIT(8)};
  `,
  tablet: css`
    margin-top: ${theme.UNIT(6)};
  `,
  desktop: css`
    margin-top: ${theme.UNIT(6)};
  `,
});

const TabsStyles = breakpoints({
  base: css`
    
  `,
});

const CommunityStyles = breakpoints({
  base: css`
    
  `,
});

const WorkingGroupsStyles = breakpoints({
  base: css`
    
  `,
});

const OverviewStyles = breakpoints({
  base: css`
    
  `,
});

const ResourcesStyles = breakpoints({
  base: css`
    
  `,
});

const PublicationsStyles = breakpoints({
  base: css`
    
  `,
});



const TitleHeaderStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(6)} 0;
    font-family: ${theme.FONT('mono')};
  `,
});




// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Header: styled(SiteHeaderLayout)`${HeaderStyles}`,
      TitleHeader: styled(MainHeaderLayout)`${TitleHeaderStyles}`,

      Layout: styled.div`${LayoutStyles}`,
        Tabs: styled(Tabs)`${TabsStyles}`,

        Overview: styled(CountryOverviewLayout)`${OverviewStyles}`,
        Community: styled(CountryCommunityLayout)`${CommunityStyles}`,
        WorkingGroups: styled(CountryWorkstreamLayout)`${WorkingGroupsStyles}`,
        Resources: styled(CountryResourcesLayout)`${ResourcesStyles}`,
        Publications: styled(CountryPublicationsLayout)`${PublicationsStyles}`

};

export const CountryLayout: Shapeable.FC<CountryLayoutProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);

  const { workstreams = [], roles = [], feedEntries = [], posts = [], files = [] } = entity;


  const hasWorkstreams = !!workstreams.length;
  const hasRoles = !!roles.length;
  // const hasFeedEtries = !!feedEntries.length 
  // const hasPosts = !!posts.length;
  // const hasPublications = hasFeedEtries || hasPosts;
  // const hasAssets = !!files.length;

  const showOverviewTab = hasWorkstreams || hasRoles;

  const { isAuthenticated } = useAuth();
  const { isLoading, person } = useActivePerson();
  const { activeView, setActiveView } = useView<CountryProfileView>('commodity-group', 'overview');
  const hasProfileAccess = isAuthenticated && !isLoading && person.memberOfEconomies?.find((e) => e.id === entity.id);

  const tabs: TabSpec<CountryProfileView>[] = compact([
    showOverviewTab &&
    {
      id: 'overview',
      label: 'Overview',
    },
    hasWorkstreams &&
    {
      id: 'activity',
      label: 'Activity',
    },
    hasRoles &&
    {
      id: 'community',
      label: 'Community',
    },
    // hasAssets &&
    // {
    //   id: 'assets',
    //   label: 'Assets And Resources',
    // },
    // hasPublications &&
    // {
    //   id: 'publications',
    //   label: 'Posts And Articles',
    // },
  ]);
 
  return (
    <SiteHeaderProvider value={{
      variant: 'default',
    }}>
    <ComponentPropsProvider value={{
      SiteHeaderLayout: {
        colors: {
          background: 'dark',
        }
      }
    }}>
   <My.Container className={cls.name(className)}>
    <My.Header />
    
    <SliceLayoutBoundary>
      <My.TitleHeader entity={entity} />
    </SliceLayoutBoundary>
      <AnimateHeight duration={500} height={!hasProfileAccess ? 0 : 'auto'}>
      <My.Tabs onTabClick={setActiveView} activeTab={activeView} tabs={tabs} variant='section'/>
      </AnimateHeight>
      <My.Layout>
       {
        (activeView === 'overview' || !hasProfileAccess) && <My.Overview entity={entity} />
       }
       {
          hasProfileAccess && 
          <>
          {
            activeView === 'activity' &&
             <SliceLayoutBoundary><My.WorkingGroups items={workstreams} /></SliceLayoutBoundary>
          }
          {
            activeView === 'community' &&
              <SliceLayoutBoundary><My.Community items={roles} /></SliceLayoutBoundary>
          }
          {/* {
            activeView === 'assets' &&
              <SliceLayoutBoundary><My.Resources /></SliceLayoutBoundary>
          } */}
          {/* {
            activeView === 'publications' &&
              <SliceLayoutBoundary boundary='feature'><My.Publications entity={entity} /></SliceLayoutBoundary>
          } */}
          </>
       }
      
      </My.Layout>
   {children}
   </My.Container>
   </ComponentPropsProvider>
  </SiteHeaderProvider>
  )
};

CountryLayout.defaultProps = CountryLayoutDefaultProps;
CountryLayout.cls = cls;