import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, HasStyle, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { DottedLine, useTheme} from '@shapeable/ui';
import { classNames } from '@shapeable/utils';
import { PulseLoader as Spinner } from 'react-spinners';

const cls = classNames('tab');

// -------- Types -------->

export type TabVariant = 'grid' | 'section';

export type TabProps = Classable & HasChildren & HasStyle & {
  isActive?: boolean;
  nextIsActive?: boolean;
  isLoading?: boolean;
  isLast?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  variant?: TabVariant;
  count?: number;
}

export const TabDefaultProps: TabProps = {
  isActive: false,
  isLast: false,
  variant: 'grid',
};

// -------- Child Component Props -------->

type ContainerProps = {
  isActive: boolean;
  isLast: boolean;
  variant: TabVariant;
}

type CountProps = {
  count: number;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    cursor: pointer;
    flex-wrap: nowrap;
    box-sizing: border-box;
    font-weight: 400; 
    padding: 0;
    flex-grow: 1;
    color: ${theme.COLOR('brightWarm')};
    text-align: center;
    font-family: ${theme.FONT('sans')};
    font-size: 1em;
    background-color: ${theme.COLOR('canvas')};
    border: none;
    height: ${theme.UNIT(9)};
    font-size: ${theme.FONT_SIZE(14)}; 

    &:hover {
      color: ${theme.COLOR('primary')};
    }

    ${({ variant }: ContainerProps ) => variant === 'section' && css`
        color: ${theme.COLOR('text')};
        height: ${theme.UNIT(15)};
        border-top: 2px dotted transparent;
        border-right: 2px dotted ${theme.COLOR('line-semi-subtle')};
        border-bottom: 2px dotted ${theme.COLOR('line-semi-subtle')};

        &:last-child {
          border-right: none;
        }

        &:hover {
          color: ${theme.COLOR('primary')};
        }
    `}

    ${({ isActive, variant }: ContainerProps ) => isActive && variant === 'section' && css`
      font-weight: 500; 
      cursor: default;
      border-bottom-color: transparent;
      border-top: 2px dotted ${theme.COLOR('line-semi-subtle')};
      color: ${theme.COLOR('strong')};

      &:hover {
        color: ${theme.COLOR('strong')};
        background-color: ${theme.COLOR('canvas')};
      }
    `}

    ${({ isActive, variant }: ContainerProps ) => isActive && variant === 'grid' && css`
      border-radius: ${theme.UNIT(2)} ${theme.UNIT(2)} 0 0;
      background-color: ${theme.COLOR('brightWarm')};
      
      color: ${theme.COLOR('light')};
      border: none;
      cursor: default;
      font-weight: 500;

      &:hover {
        color: ${theme.COLOR('light')};
        background-color: ${theme.COLOR('brightWarm')};
      }
    `
  }
  `,
  tablet: css`
    font-size: ${theme.FONT_SIZE(16)}; 
     ${({ variant }: ContainerProps ) => variant === 'grid' && css`
      height: ${theme.UNIT(11)};
    `}
 `,
 desktop: css`
 `,
});

const LabelStyles = breakpoints({
  base: css`
    display: flex;
    justify-content: center;
    white-space: nowrap;
    flex-grow: 1;
    padding: 0 ${theme.UNIT(3)};
    gap: ${theme.UNIT(1)};
  `,
});

const TabCountStyles = breakpoints({
  base: css`
    ${({ count }: CountProps ) => (count && css`
      color: ${theme.COLOR('primary')};
    `
    )}
  `,
});

const DottedLineStyles = breakpoints({
  base: css`
    flex-grow: 0;
    flex-shrink: 1;
  `,
});

const SpinnerStyles = breakpoints({
  base: css`
    
  `,
});





// -------- Components -------->

const My = {
  Container: styled.button<ContainerProps>`${ContainerStyles}`,
    Label: styled.span`${LabelStyles}`,
    Spinner: styled(Spinner)`${SpinnerStyles}`,
    DottedLine: styled(DottedLine)`${DottedLineStyles}`,
    TabCount: styled.span<CountProps>`${TabCountStyles}`,
};

export const Tab: Shapeable.FC<TabProps> = (props) => {
  const { className, children, isActive, nextIsActive, onClick, isLast, variant, count, isLoading } = props;

  const theme = useTheme();

  return (
    <My.Container onClick={onClick} isActive={isActive} variant={variant} isLast={isLast} className={cls.name(className)}>
      <My.Label>
        {children}
        {
          isLoading ?
          <Spinner color={theme?.color?.primary} size={6}  />
          : (
            !!count && variant === 'section' && 
            <My.TabCount count={count}>({count})</My.TabCount>
          )
        }
      </My.Label>
      
      {!isActive && !nextIsActive && !isLast && variant === 'grid' && <My.DottedLine width={2} gap={5} />}
    </My.Container>
  )
};

Tab.cls = cls;
Tab.defaultProps = TabDefaultProps;