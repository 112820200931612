import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { MainAside, useEntity } from '@shapeable/ui';
import { classNames } from '@shapeable/utils';
const cls = classNames('resources-layout');

// -------- Types -------->

export type CountryResourcesLayoutProps = Classable & HasChildren & { 
  entity?: Entity;
};

export const CountryResourcesLayoutDefaultProps: Omit<CountryResourcesLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const BodyStyles = breakpoints({
  base: css`
    width: 100%;
  `,
});

const MainAsideStyles = breakpoints({
  base: css`
    font-size: ${theme.FONT_SIZE(15)};
    padding-bottom: ${theme.UNIT(6)};
  `,
  tablet: css`
  `,
});

const AsideStyles = breakpoints({
  base: css`
    padding-top: ${theme.UNIT(4)};
  `,
  desktop: css`
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Body: styled.div`${BodyStyles}`,
      Main: styled.div`${MainAsideStyles}`,
        Aside: styled.div`${AsideStyles}`,
      
};

export const CountryResourcesLayout: Shapeable.FC<CountryResourcesLayoutProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);
  
  return (
   <My.Container className={cls.name(className)}>
      <My.Body>
        <MainAside 
          main={
            <My.Main>

                
            </My.Main>
          }
          aside={
            <My.Aside>
              

            </My.Aside>
        }>

        </MainAside>
      </My.Body>
    {children}
    </My.Container>
  )
};

CountryResourcesLayout.defaultProps = CountryResourcesLayoutDefaultProps;
CountryResourcesLayout.cls = cls;