import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { useEntity, OutlineEntityBubble, EntityTypeHeader, CellHeader } from '@shapeable/ui';
const cls = classNames('entity-main-header');

// -------- Types -------->

export type EntityMainTitleHeaderProps = Classable & HasChildren & { 
  entity?: Entity;
  label?: string;
  imageSize?: number;
};

export const EntityMainTitleHeaderDefaultProps: Omit<EntityMainTitleHeaderProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

type ImageProps = {
  imageSize?: number;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    font-size: 0.8em;
  `,
  tablet: css`
    font-size: 0.9em;
  `,
  desktop: css`
    font-size: 1em;
  `,
});

const BodyStyles = breakpoints({
  base: css`
    display: flex;
    gap: ${theme.UNIT(4)};
  `,
});


const OutlineBubbleStyles = breakpoints({
  base: css`
    flex-shrink: 0;
    ${({ imageSize }: ImageProps) => imageSize && css`
      width: ${imageSize}px;
      height: ${imageSize}px;
    `}
  `,
});



const ControlsStyles = breakpoints({
  base: css`
    
  `,
});

const TypeAndNameStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${theme.UNIT(1)};   
    margin-top: ${theme.UNIT(2)};
  `,
});

const LabelStyles = breakpoints({
  base: css`
    
  `,
});



const TypeStyles = breakpoints({
  base: css`
    
  `,
});

const NameStyles = breakpoints({
  base: css`
    line-height: 1.1em;
    margin: 0;
    font-size: ${30/16}em;
    font-weight: 500;
  `,
});






// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Body: styled.div`${BodyStyles}`,
      TypeAndName: styled.div`${TypeAndNameStyles}`,
        Type: styled(EntityTypeHeader)`${TypeStyles}`,
        Label: styled(CellHeader)`${LabelStyles}`,
        Name: styled.h1`${NameStyles}`,
      OutlineBubble: styled(OutlineEntityBubble)<ImageProps>`${OutlineBubbleStyles}`,
      
    Controls: styled.div`${ControlsStyles}`,
};

export const EntityMainTitleHeader: Shapeable.FC<EntityMainTitleHeaderProps> = (props) => {
  const { className, children, imageSize } = props;
  const entity = useEntity(props.entity);

  const title = !!props.label;
  
  return (
   <My.Container className={cls.name(className)}>
      <My.Body>
        <My.OutlineBubble imageSize={imageSize} entity={entity} />
        <My.TypeAndName>
          {
            title ? <My.Label>{props.label}</My.Label> : <My.Type entity={entity} />
          }
          <My.Name>{entity.name}</My.Name>
        </My.TypeAndName>
      </My.Body>
   {children}
   </My.Container>
  )
};

EntityMainTitleHeader.defaultProps = EntityMainTitleHeaderDefaultProps;
EntityMainTitleHeader.cls = cls;