import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity, IndicatorValue, Dictionary } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { useEntity, useInViewEffect } from '@shapeable/ui';
import { classNames } from '@shapeable/utils';
import { SegmentBar } from '../elements/segment-bar';
import { COLOR_1_TO_5_1, COLOR_1_TO_5_2, COLOR_1_TO_5_3, COLOR_1_TO_5_4, COLOR_1_TO_5_5 } from '../../theme';
const cls = classNames('indicator-value-bar');

// -------- Types -------->

export type IndicatorValueBarPossibleValues = 1 | 2 | 3 | 4 | 5;
export const IndicatorValueBarColors: Record<IndicatorValueBarPossibleValues, string> = {
  1: COLOR_1_TO_5_1,
  2: COLOR_1_TO_5_2,
  3: COLOR_1_TO_5_3,
  4: COLOR_1_TO_5_4,
  5: COLOR_1_TO_5_5,
} 

export type IndicatorValueBarProps = Classable & HasChildren & { 
  entity?: IndicatorValue;
};

export const IndicatorValueBarDefaultProps: Omit<IndicatorValueBarProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    font-size: 1em;
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
});

const TitleStyles = breakpoints({
  base: css`
    width: 50%;
    font-weight: 400;
    font-size: 1em;
    padding-right: ${theme.UNIT(4)};
    box-sizing: border-box;
    margin: 0;
  `,
});

const ChartStyles = breakpoints({
  base: css`
    width: 50%;
  `,
});





// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Title: styled.h3`${TitleStyles}`,
    Chart: styled(SegmentBar)`${ChartStyles}`
};

export const IndicatorValueBar: Shapeable.FC<IndicatorValueBarProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);
  
  const [ value, setValue ] = React.useState<number>(0);

  const ref = useInViewEffect(() => {
    setValue(entity.numericValue);
  });

  
  return (
    <My.Container ref={ref} className={cls.name(className)}>
      <My.Title>{entity?.indicator?.title || entity?.indicator?.name}</My.Title>
      <My.Chart 
        height={10}
        maxValue={5}
        value={value}
        color={IndicatorValueBarColors[Math.floor(entity.numericValue) as IndicatorValueBarPossibleValues]}
      />
    </My.Container>
  )
};

IndicatorValueBar.defaultProps = IndicatorValueBarDefaultProps;
IndicatorValueBar.cls = cls;