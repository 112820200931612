import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';

import { classNames } from '@shapeable/utils';
import { COLOR_INDICATOR_VALUE_BG } from '../../theme';
const cls = classNames('segment-bar');

// -------- Types -------->

export type SegmentBarProps = Classable & HasChildren & {
  backgroundColor?: string;
  value: number;
  color?: string;
  tickWidth?: number;
  tickColor?: string;
  height?: number;
  maxValue?: number; // max value of the segment bar
}

export const SegmentBarDefaultProps: Omit<SegmentBarProps, 'value'> = {
  height: 10,
  maxValue: 5,
  tickWidth: 2,
  backgroundColor: '#FFFFFF',
  tickColor: COLOR_INDICATOR_VALUE_BG,
  
};

// -------- Child Component Props -------->

type ContainerProps = {
  _height: number;
  _color: string;
};

type TickProps = {
  _width: number;
  _height: number;
  _color: string;
  _left: number;
};

type BarProps = {
  _width: number;
  _color: string;
}
// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    position: relative;
    ${({ _height, _color }: ContainerProps ) => css`
      height: ${_height}px;
      background-color: ${_color};

    `}
  `,
});

const TickStyles = breakpoints({
  base: css`
    position: absolute;
    top: 0;
    font-size: 0;
    z-index: 2;
    ${({ _color, _height, _left, _width }: TickProps ) => css`
      height: ${_height}px;
      width: ${_width}px;
      background-color: ${_color};
      left: calc(${_left}% - ${_width / 2}px);
    `}
  `,
});

const BarStyles = breakpoints({
  base: css`
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    position: absolute;
    transition: width 1s ease-out;
    ${({ _color, _width }: BarProps ) => css`
      width: ${_width}%;
      background-color: ${_color};
    `}
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,

    Tick: styled.span<TickProps>`${TickStyles}`,
    Bar: styled.span<BarProps>`${BarStyles}`,

};

export const SegmentBar: Shapeable.FC<SegmentBarProps> = (props) => {
  const { className, color, height, backgroundColor, tickColor, tickWidth, value, maxValue } = props;

  const tickCount = maxValue;
  
  return (
    <My.Container _color={backgroundColor} _height={height} className={cls.name(className)}>
    {
      tickCount >= 1 && [...Array(tickCount).keys()].map((i) => 
        <My.Tick key={i} _height={height} _color={tickColor} _width={tickWidth} _left={Math.floor((i / tickCount) * 100)} />
      )
    }
    <My.Bar _width={(value / maxValue) * 100} _color={color} />
    </My.Container>
  )
};

SegmentBar.cls = cls;
SegmentBar.defaultProps = SegmentBarDefaultProps;