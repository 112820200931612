import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { MainAside, WorkstreamCard } from '@shapeable/ui';
import { Workstream } from '@shapeable/lli-types';
import { classNames } from '@shapeable/utils';
const cls = classNames('workstream-layout');

// -------- Types -------->

export type CountryWorkstreamLayoutProps = Classable & HasChildren & { 
  items: Workstream[];
};

export const CountryWorkstreamLayoutDefaultProps: CountryWorkstreamLayoutProps = {
  items: []
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const BodyStyles = breakpoints({
  base: css`
    width: 100%;
  `,
});

const MainAsideStyles = breakpoints({
  base: css`
    font-size: ${theme.FONT_SIZE(18)};
    padding-bottom: ${theme.UNIT(6)};
  `,
  tablet: css`
  `,
});

const AsideStyles = breakpoints({
  base: css`
    padding-top: ${theme.UNIT(4)};
  `,
  desktop: css`
  `,
});

const WorkstreamsStyles = breakpoints({
  base: css`
    
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
  Body: styled.div`${BodyStyles}`,
    Main: styled.div`${MainAsideStyles}`,
      Aside: styled.div`${AsideStyles}`,

      Workstreams: styled(WorkstreamCard)`${WorkstreamsStyles}`
};

export const CountryWorkstreamLayout: Shapeable.FC<CountryWorkstreamLayoutProps> = (props) => {
  const { className, children, items } = props;
  
  return (
    <My.Container className={cls.name(className)}>
      <My.Body>
        {/* <MainAside 
          aside={
            <My.Main>
              { 
                items.map((workstream, index) => <My.Workstreams key={workstream.id} number={index + 1} entity={workstream}>
                  
                </My.Workstreams>)
              }
            </My.Main>
          }
          >
        </MainAside> */}
        <My.Main>
          { 
            items.map((workstream, index) => <My.Workstreams key={workstream.id} number={index + 1} entity={workstream}>
              
            </My.Workstreams>)
          }
        </My.Main>
      </My.Body>
    {children}
    </My.Container>
  )
};

CountryWorkstreamLayout.defaultProps = CountryWorkstreamLayoutDefaultProps;
CountryWorkstreamLayout.cls = cls;