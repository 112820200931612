import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity, Person } from '@shapeable/types';
import { EconomyRole } from '@shapeable/lli-types';
import { breakpoints, theme } from '@shapeable/theme';
import { ContentTitle, MainAside, PersonBioCell, PersonBioGrid, useEntity, useLang } from '@shapeable/ui';
import { map, filter } from 'lodash';
import { classNames } from '@shapeable/utils';
const cls = classNames('community-layout');

// -------- Types -------->

export type CountryCommunityLayoutProps = Classable & HasChildren & { 
  items: EconomyRole[];
};

export const CountryCommunityLayoutDefaultProps: CountryCommunityLayoutProps = {
  items: []
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const BodyStyles = breakpoints({
  base: css`
    width: 100%;
  `,
});

const MainStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    font-size: ${theme.FONT_SIZE(15)};
    padding-bottom: ${theme.UNIT(6)};
  `,
  tablet: css`
  `,
});

const AsideStyles = breakpoints({
  base: css`
    padding-top: ${theme.UNIT(4)};
  `,
  desktop: css`
  `,
});

const PeopleContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-wrap: wrap;
    font-size: ${theme.FONT_SIZE(15)};
    padding-bottom: ${theme.UNIT(12)};
  `,
});

const PeopleStyles = breakpoints({
  base: css`
    background: ${theme.COLOR('canvas')};
    width: 100%;
    min-height: 230px;
    box-sizing: border-box;
    padding: 48px 12px 20px 12px;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justfiy-content: center;
    flex: 1 0 0;
    `,

});

const ContentTitleStyles = breakpoints({
  base: css`
   padding-bottom: ${theme.UNIT(4)};
   align-self: center;
   font-weight: 500;
   line-height: 1.1em;
   text-transform: uppercase;
   font-size: 2rem;
   font-family: ${theme.FONT('sans')};
   text-align: center;
   color: ${theme.COLOR('strong')};
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Body: styled.div`${BodyStyles}`,
      Main: styled.div`${MainStyles}`,
        Aside: styled.div`${AsideStyles}`,
        PeopleContainer: styled.div`${PeopleContainerStyles}`,
        People: styled(PersonBioCell)`${PeopleStyles}`,

        ContentTitle: styled(ContentTitle)`${ContentTitleStyles}`,
};

export const CountryCommunityLayout: Shapeable.FC<CountryCommunityLayoutProps> = (props) => {
  const { className, children, items } = props;

  const t = useLang();

  const leads = filter(items, (person) => { return person.role?.slug === 'lead'; });
  const members = filter(items, (person) => { return person.role?.slug === 'member'; });
  const internatioalSignatory = filter(items, (person) => { return person.role?.slug === 'international-signatory'; });
  const cityRepresentative = filter(items, (person) => { return person.role?.slug === 'partner-city-representative'; });
  const steward = filter(items, (person) => { return person.role?.slug === 'steward'; });

  return (
    <My.Container className={cls.name(className)}>
      <My.Body>
        <My.Main>
        
        {
          leads.length > 0 && <>
          <My.ContentTitle>{t('Lead')}</My.ContentTitle>
          <My.PeopleContainer>
          {
            leads.map(person => <My.People key={person.id} showOrganisation entity={person.person} /> 
          )}
          </My.PeopleContainer>
          </>
        }
        {
          members.length > 0 && <>
          <My.ContentTitle>{t('Member')}</My.ContentTitle>
          <My.PeopleContainer>
          {
            members.map(person => <My.People key={person.id} showOrganisation entity={person.person} />
          )}
          </My.PeopleContainer>
          </>
        }
        {
          internatioalSignatory.length > 0 && <>
          <My.ContentTitle>{t('International Signatory')}</My.ContentTitle>
          <My.PeopleContainer>
          {
            internatioalSignatory.map(person => <My.People key={person.id} showOrganisation entity={person.person} />
          )}
          </My.PeopleContainer>
          </>
        }
        {
          cityRepresentative.length > 0 && <>
          <My.ContentTitle>{t('Partner City Representative')}</My.ContentTitle>
          <My.PeopleContainer>
          {
            cityRepresentative.map(person => <My.People key={person.id} showOrganisation entity={person.person} />
          )}
          </My.PeopleContainer>
          </>
        }
        {
          steward.length > 0 && <>
          <My.ContentTitle>{t('Steward')}</My.ContentTitle>
          <My.PeopleContainer>
          {
            steward.map(person => <My.People key={person.id} showOrganisation entity={person.person} />
          )}
          </My.PeopleContainer>
          </>
        }

        </My.Main>
      </My.Body>
    {children}
    </My.Container>
  )
};

CountryCommunityLayout.defaultProps = CountryCommunityLayoutDefaultProps;
CountryCommunityLayout.cls = cls;