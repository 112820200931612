import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { TabsContainer, useActiveEntity, useLang, useLink } from '@shapeable/ui';
import { TabVariant } from './tab';
import { compact } from 'lodash';
import { Tab } from './tab';
import { classNames } from '@shapeable/utils';
const cls = classNames('tabs');


// -------- Types -------->

export type TabSpec<IdType extends string = string> = {
  id: IdType;
  label: string;
  isLoading?: boolean;
  count?: number;
}

// -------- Props -------->

export type TabsProps = Classable & HasChildren & {
  tabs: TabSpec[];
  onTabClick?: (tab: string) => void;
  activeTab?: string;
  variant?: TabVariant;
}

export const TabsDefaultProps: TabsProps = {
  activeTab: 'all',
  tabs: [],
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    align-items: flex-start;
    width: 100%;
    flex-direction: row;
  `,
  desktop: css`
    padding: 0;
    box-sizing: border-box;
  `,
});

const TabStyles = breakpoints({
  base: css`
    
  `,
});



// -------- Components -------->

const My = {
  Container: styled(TabsContainer)<ContainerProps>`${ContainerStyles}`,
    Tab: styled(Tab)`${TabStyles}`,
};

export const Tabs: Shapeable.FC<TabsProps> = (props) => {
  const { className, onTabClick, tabs, activeTab, variant } = props;

  const t = useLang();

  const handleClick = (tab: TabSpec) => () => {
    if (onTabClick) {
      onTabClick(tab.id);
    }
  };

  return (
    <My.Container showFade={false} className={cls.name(className)}>
      {
        compact(tabs).map((tab, index) => {
          const nextTab = index < tabs.length - 1 ? tabs[index + 1] : null;
          const nextIsActive = nextTab && nextTab.id === activeTab;
          const isLast = index === tabs.length - 1;
          
          return (
            <My.Tab isLoading={tab.isLoading} onClick={handleClick(tab)} key={tab.id} count={tab.count} nextIsActive={nextIsActive} variant={variant} isLast={isLast} isActive={activeTab === tab.id}>{t(tab.label)}</My.Tab>  
          );

        })
      }
    </My.Container>
  )
};

Tabs.cls = cls;
Tabs.defaultProps = TabsDefaultProps;