import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, IndicatorValue } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { ContentNode, useEntity, useInViewEffect } from '@shapeable/ui';
import { classNames } from '@shapeable/utils';
const cls = classNames('indicator-value-number');
import Countup from 'react-countup';
import { SdgTargetThree } from '../icons/sdg-target-three';

// -------- Types -------->

export type IndicatorValueNumberProps = Classable & HasChildren & { 
  entity?: IndicatorValue;
  isFeature?: boolean;
  hasSdg?: boolean;
};

export const IndicatorValueNumberDefaultProps: Omit<IndicatorValueNumberProps, 'entity'> = {
  isFeature: false,
  hasSdg: false,
};

// -------- Child Component Props -------->

type ContainerProps = {
  isFeature: boolean;
}

type ValueProps = {
  isFeature: boolean;
  hasSdg?: boolean;
}

type DescriptionProps = {
  isFeature: boolean;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    width: 100%;
    display: flex;
    flex-direction: column;
  `,
  desktop: css`
    ${({ isFeature }: ContainerProps ) => isFeature && css`
      flex-direction: row;
      align-items: center;
    `}
  `
});

const ValueStyles = breakpoints({
  base: css`
    font-family: ${theme.FONT('mono')};
    margin-bottom: ${theme.UNIT(6)};
    display: block;
    ${({ isFeature }: ValueProps ) => css`
      font-size: ${(isFeature ? 60 : 30)/16}em; 
    `}
    ${({ hasSdg }: ValueProps ) => hasSdg && css`
      display: flex;
      flex-direction: row;
      gap: ${theme.UNIT(4)};
      margin-bottom: ${theme.UNIT(2)};
    `}

    ${({ isFeature }: ValueProps ) => isFeature && css`
      width: 50%;
      margin-bottom: 0;
    `}
  `,
  tablet: css`
    ${({ isFeature }: ValueProps ) => css`
      font-size: ${(isFeature ? 80 : 40)/16}em; 
    `}
    ${({ hasSdg }: ValueProps ) => hasSdg && css`
      font-size: 2.2em; 
    `}
  `
});

const NumberStyles = breakpoints({
  base: css`
    font-weight: 600;
  `,
});


const SuffixStyles = breakpoints({
  base: css`
    font-weight: 400;

  `,
});


const DescriptionStyles = breakpoints({
  base: css`
    font-weight: 400;
    box-sizing: border-box;
    font-size: ${14/16}em;
    ${({ isFeature }: ValueProps ) => isFeature && css`
      width: 50%;
    `}
  `,
  desktop: css`
    ${({ isFeature }: ValueProps ) => isFeature && css`
      padding: 0 ${theme.UNIT(10)};
    `}
  `
});

const IconsStyles = breakpoints({
  base: css`
    margin-top: -8px;
  `,
});

const SdgIconStyles = breakpoints({
  base: css`

  `,
});

const ValueDataStyles = breakpoints({
  base: css`

  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Value: styled.b<ValueProps>`${ValueStyles}`,
      ValueData: styled.div`${ValueDataStyles}`,
      Number: styled.span`${NumberStyles}`,
      Suffix: styled.span`${SuffixStyles}`,
    Description: styled(ContentNode)<DescriptionProps>`${DescriptionStyles}`,
    Icons: styled.div.attrs(cls.attr('icon'))`${IconsStyles}`,
    SdgIcon: styled(SdgTargetThree)`${SdgIconStyles}`
};

export const IndicatorValueNumber: Shapeable.FC<IndicatorValueNumberProps> = (props) => {
  const { className, children, isFeature, hasSdg } = props;
  const entity = useEntity(props.entity);
  
  const { indicator } = entity;
  const unit = indicator?.unit;
  const [ value, setValue ] = React.useState<number>(0);

  const ref = useInViewEffect(() => {
    setValue(entity.numericValue);
  });

  const getPrecision = (numericValue: number) => {
    if (numericValue === 0) return 0;
    const digits = Math.ceil(-Math.log10(numericValue) + 1);
    return Math.max(digits, indicator?.precision || 0);
  };

  const precision = getPrecision(entity?.numericValue);

  return (
     <My.Container isFeature={isFeature} ref={ref} className={cls.name(className)}>
      <My.Value isFeature={isFeature} hasSdg={hasSdg}>
        {
          hasSdg && <My.Icons><My.SdgIcon /></My.Icons>
        }
        <My.ValueData>
        <My.Number><Countup duration={1} smartEasingAmount={1} decimals={precision} end={value} /></My.Number>
        {
          unit?.suffix &&
          <My.Suffix>{unit.suffix}</My.Suffix>
        }
        </My.ValueData>
      </My.Value>
      {
        indicator?.description?.text &&
        <My.Description isFeature={isFeature} entity={indicator.description} />
      }
    </My.Container>
  )
};

IndicatorValueNumber.defaultProps = IndicatorValueNumberDefaultProps;
IndicatorValueNumber.cls = cls;